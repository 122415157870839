<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div>
        <router-view></router-view>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
</style>

<script>

export default {
  name: 'Infevents',
  props: {
  },
  data() {
    return {
    };
  }
};
</script>
